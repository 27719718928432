// dependencies
import React from "react";
import { StyleSheet } from "aphrodite";

// components
import Placeholder from "@gdf/resources/src/components/Placeholder";

const styles = StyleSheet.create({
  placeholder: {
    height: "100%",
    minHeight: "29.375rem",
  },
});

type PropsType = {};

const RoomCardPlaceholder: React.FunctionComponent<PropsType> = () => {
  return <Placeholder aStyle={styles.placeholder} />;
};

RoomCardPlaceholder.defaultProps = {};

export default RoomCardPlaceholder;
