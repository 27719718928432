// dependencies
import React from "react";
import { useIntl } from "react-intl";
import { StyleSheet } from "aphrodite";

// components
import Text from "@gdf/resources/src/components/Text2";
import { Row, Col } from "@gdf/resources/src/components/Grid2";

// libraries
import { emToPx } from "@gdf/resources/src/libraries";
import { get } from "@gdf/shared/src/libraries";

// helpers
import { roomIsPricingAvailable } from "@gdf/resources/src/helpers/room";

// constants
import theming from "../../constants/theming";

type PropsType = {
  pricing: ReturnType<
    typeof import("../../normalizers/pricing/estimate/parseResource").default
  >;
};

const styles = StyleSheet.create({
  price: {
    fontSize: "1.625rem",
  },
  priceOld: {
    fontSize: "0.75rem",
    textDecorationLine: "line-through",
    marginRight: emToPx(0.5),
  },
  discount: {
    fontSize: "0.75rem",
  },
  col: {
    display: "flex",
    alignItems: "center",
  },
});

const RoomPricing: React.FunctionComponent<PropsType> = ({ pricing }) => {
  const intl = useIntl();

  const { useTheme } = theming;
  const theme = useTheme();

  if (!pricing) {
    return null;
  }

  if (!roomIsPricingAvailable({ pricing }) || undefined === pricing?.amount) {
    return null;
  }

  const oldAmount = pricing?.oldAmount;
  const amount = pricing?.amount;

  return (
    <Row
      type="inline"
      flexWrap={true}
      gutter={{ vertical: 0, horizontal: 0.5 }}
    >
      <Col aStyle={styles.col}>
        <Text>
          {intl.formatMessage(
            {
              id: "app.components.room.priceLabel",
            },
            {
              priceType: pricing?.meta?.isUsingCheckinAndCheckout
                ? "trip"
                : "night",
            }
          )}
        </Text>
      </Col>

      <Col aStyle={styles.col}>
        {oldAmount > amount && (
          <div>
            <Text
              aStyle={[styles.discount]}
              style={{ color: theme.SECONDARY_COLOR }}
            >
              {intl.formatNumber(-get(pricing, "discount.rate"), {
                style: "percent",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Text>
            <Text aStyle={styles.priceOld}>
              {intl.formatNumber(oldAmount, {
                style: "currency",
                currency: "EUR",
              })}
            </Text>
          </div>
        )}
        <div>
          <Text aStyle={styles.price}>
            {intl.formatNumber(amount, {
              style: "currency",
              currency: "EUR",
            })}
          </Text>
        </div>
      </Col>
    </Row>
  );
};

export default RoomPricing;
