// dependencies
import React, { useMemo } from "react";
import { StyleSheet } from "aphrodite";

// components
import Text from "@gdf/resources/src/components/Text2";

// constants
import theming from "@gdf/resources/src/constants/theming";

const { useTheme } = theming;

const styles = StyleSheet.create({
  tag: {
    borderStyle: "solid",
    borderWidth: "0.0625rem",
    fontSize: "0.75rem",
    borderRadius: "0.1875rem",
    padding: "0.1875rem",
  },
});

type PropsType = {
  aStyle?;
  backgroundColor?: string;
  borderColor?: string;
  textColor?: string;
} & React.HTMLProps<HTMLDivElement>;

const Tag: React.FunctionComponent<PropsType> = (props) => {
  const { aStyle, ...otherProps } = props;

  const theme = useTheme();

  const dynamicStyles = useMemo(
    () =>
      StyleSheet.create({
        tag: {
          color: theme.PRIMARY_COLOR_TEXT,
          backgroundColor: "#ffffff",
          borderColor: "#ffffff",
        },
      }),
    [theme]
  );

  return (
    <Text aStyle={[styles.tag, dynamicStyles.tag, aStyle]} {...otherProps} />
  );
};

export default Tag;
