// dependencies
import React from "react";
import { useIntl } from "react-intl";
import { StyleSheet } from "aphrodite";

// components
import Tag from "@gdf/resources/src/components/Tag";

const styles = StyleSheet.create({
  tag: {
    backgroundColor: "#7AA62E",
    borderColor: "#7AA62E",
    borderWidth: "0.0625rem",
    borderStyle: "solid",
    color: "#ffffff",
    fontSize: "0.875rem",
  },
});

export default function RoomNewTag(props) {
  const { aStyle } = props;

  const intl = useIntl();

  return (
    <Tag aStyle={[styles.tag, aStyle]}>
      {intl.formatMessage({ id: "app.component.roomAllInclusiveRate.label" })}
    </Tag>
  );
}
