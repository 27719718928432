// dependencies
import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import { View, StyleSheet, Dimensions, ScaledSize } from "react-native";

// components
import Portal from "@gdf/resources/src/components/Portal";

const computePosition = {
  bottom: {
    center({ rect, popoverRect, windowRect, scrollRect, gutter }) {
      return {
        x: Math.min(
          windowRect.width - popoverRect.width,
          Math.max(
            0,
            rect.x + rect.width / 2 - popoverRect.width / 2 + scrollRect.x
          )
        ),
        y: rect.y + rect.height + gutter + scrollRect.y,
      };
    },
    start({ rect, popoverRect, windowRect, scrollRect, gutter }) {
      return {
        x: Math.min(
          windowRect.width - popoverRect.width,
          Math.max(0, rect.x + scrollRect.x)
        ),
        y: rect.y + rect.height + gutter + scrollRect.y,
      };
    },
    end({ rect, popoverRect, windowRect, scrollRect, gutter }) {
      return {
        x: Math.min(
          windowRect.width - popoverRect.width,
          Math.max(0, rect.x + rect.width - popoverRect.width + scrollRect.x)
        ),
        y: rect.y + rect.height - gutter + scrollRect.y,
      };
    },
  },
  top: {
    center({ rect, popoverRect, windowRect, scrollRect, gutter }) {
      return {
        x: Math.min(
          windowRect.width - popoverRect.width,
          Math.max(
            0,
            rect.x + rect.width / 2 - popoverRect.width / 2 + scrollRect.x
          )
        ),
        y: rect.y - popoverRect.height - gutter + scrollRect.y,
      };
    },
    start({ rect, popoverRect, windowRect, scrollRect, gutter }) {
      return {
        x: Math.min(
          windowRect.width - popoverRect.width,
          Math.max(0, rect.x + scrollRect.x)
        ),
        y: rect.y - popoverRect.height - gutter + scrollRect.y,
      };
    },
    end({ rect, popoverRect, windowRect, scrollRect, gutter }) {
      return {
        x: Math.min(
          windowRect.width - popoverRect.width,
          Math.max(0, rect.x + rect.width - popoverRect.width + scrollRect.x)
        ),
        y: rect.y - popoverRect.height - gutter + scrollRect.y,
      };
    },
  },
  right: {
    center({ rect, popoverRect, windowRect, scrollRect, gutter }) {
      return {
        x: Math.min(
          windowRect.width - popoverRect.width,
          Math.max(0, rect.x + rect.width + gutter + scrollRect.x)
        ),
        y: rect.y + rect.height / 2 - popoverRect.height / 2 + scrollRect.y,
      };
    },
    start({ rect, windowRect, popoverRect, scrollRect, gutter }) {
      return {
        x: Math.min(
          windowRect.width - popoverRect.width,
          Math.max(0, rect.x + rect.width + gutter + scrollRect.x)
        ),
        y: rect.y + scrollRect.y,
      };
    },
    end({ rect, popoverRect, windowRect, scrollRect, gutter }) {
      return {
        x: Math.min(
          windowRect.width - popoverRect.width,
          Math.max(0, rect.x + rect.width + gutter + scrollRect.x)
        ),
        y: rect.y + rect.height - popoverRect.height + scrollRect.y,
      };
    },
  },
  left: {
    center({ rect, popoverRect, windowRect, scrollRect, gutter }) {
      return {
        x: Math.min(
          windowRect.width - popoverRect.width,
          Math.max(0, rect.x - popoverRect.width - gutter + scrollRect.x)
        ),
        y: rect.y + rect.height / 2 - popoverRect.height / 2 + scrollRect.y,
      };
    },
    start({ rect, popoverRect, windowRect, scrollRect, gutter }) {
      return {
        x: Math.min(
          windowRect.width - popoverRect.width,
          Math.max(0, rect.x - popoverRect.width - gutter + scrollRect.x)
        ),
        y: rect.y + scrollRect.y,
      };
    },
    end({ rect, popoverRect, windowRect, scrollRect, gutter }) {
      return {
        x: Math.min(
          windowRect.width - popoverRect.width,
          Math.max(0, rect.x - popoverRect.width - gutter + scrollRect.x)
        ),
        y: rect.y + rect.height - popoverRect.height + scrollRect.y,
      };
    },
  },
};

const styles = StyleSheet.create({
  popover: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: "absolute",
    backgroundColor: "transparent",
    zIndex: 100,
  },
  children: {
    backgroundColor: "transparent",
    position: "absolute",
    zIndex: 100,
  },
});

type PropsType = {
  rect: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
  alignmentPlacement?: "top" | "bottom" | "left" | "right";
  alignmentSticky?: "start" | "center" | "end";
  gutter: number;
  contentRef?;
  useRectMinWidth?: boolean;
  visible: boolean;
};

const Popover: React.FunctionComponent<PropsType> = (props) => {
  const {
    rect,
    alignmentPlacement,
    alignmentSticky,
    gutter,
    visible,
    useRectMinWidth,
    children,
  } = props;

  const fallbackContentRef = useRef<View>();

  const contentRef = props.contentRef ? props.contentRef : fallbackContentRef;

  const [popoverRect, setPopoverRect] = useState(null);

  const [popoverOrigin, setPopoverOrigin] = useState(null);

  const [windowRect, setWindowRect] = useState(null);

  const $children = useRef<View>();

  const handleChangeDimensions = useCallback<
    (param0: { window: ScaledSize; screen: ScaledSize }) => void
  >(({ window }) => {
    const { width, height } = window;

    setWindowRect({ width, height });
  }, []);

  useEffect(() => {
    Dimensions.addEventListener("change", handleChangeDimensions);

    return () => {
      Dimensions.removeEventListener("change", handleChangeDimensions);
    };
  }, [handleChangeDimensions]);

  useEffect(() => {
    const { width, height } = Dimensions.get("window");

    setWindowRect({ width, height: height });
  }, []);

  const handleLayoutChildren = useCallback(() => {
    $children.current.measureInWindow((x, y, width, height) => {
      if (
        null === popoverRect ||
        x !== popoverRect.x ||
        y !== popoverRect.y ||
        width !== popoverRect.width ||
        height !== popoverRect.height
      ) {
        setPopoverRect({
          x,
          y,
          width,
          height,
        });
      }
    });
  }, [popoverRect]);

  useEffect(() => {
    if (null !== popoverRect && null !== rect) {
      const { x, y } = computePosition[alignmentPlacement][alignmentSticky]({
        rect,
        popoverRect,
        gutter: gutter * 16,
        windowRect,
        scrollRect: { x: window.scrollX, y: window.scrollY },
      });

      if (
        null === popoverOrigin ||
        x !== popoverOrigin.x ||
        y !== popoverOrigin.y
      ) {
        setPopoverOrigin({
          x,
          y,
        });
      }
    }
  }, [
    rect,
    popoverRect,
    alignmentPlacement,
    alignmentSticky,
    windowRect,
    popoverOrigin,
    gutter,
  ]);

  return useMemo(() => {
    const canRender =
      null !== popoverRect && null !== rect && null !== popoverOrigin;

    return (
      <Portal selector="body">
        <View
          ref={(ref) => {
            $children.current = ref;
            contentRef.current = ref;
          }}
          style={[
            styles.children,
            {
              opacity: canRender && visible ? 1 : 0,
              top: canRender ? popoverOrigin.y : 0,
              left: canRender ? popoverOrigin.x : 0,
              zIndex: canRender && visible ? 0 : -1,
            },
            useRectMinWidth &&
              null !== rect &&
              rect.width && { minWidth: rect.width },
          ]}
          onLayout={handleLayoutChildren}
        >
          {children}
        </View>
      </Portal>
    );
  }, [popoverOrigin, children, visible, contentRef.current, useRectMinWidth]);
};

Popover.defaultProps = {
  alignmentPlacement: "top",
  alignmentSticky: "center",
  gutter: 0,
  useRectMinWidth: true,
};

export default Popover;
