// dependencies
import React from "react";
import { StyleSheet, css } from "aphrodite";
import { IconRate, IconRateHalf, IconRateO } from "@gdf/svg-icon-library";

type IProps = {
  type: "full" | "half" | "empty";
  grownedUp: boolean;
  last: boolean;
};

const styles = StyleSheet.create({
  star: {
    marginRight: "0.3125em",
    lineHeight: 0,
    fontSize: "1em",
  },
  star__last: {
    marginRight: 0,
  },
  grownedUpStar: {
    marginRight: "0.5rem",
    transform: "scale(1.5)",
  },
});

const Star: React.FunctionComponent<IProps> = (props) => {
  const { type, last, grownedUp } = props;

  let Icon;

  switch (type) {
    case "full": {
      Icon = IconRate;
      break;
    }

    case "half": {
      Icon = IconRateHalf;
      break;
    }

    case "empty": {
      Icon = IconRateO;
      break;
    }
  }

  return (
    <div
      className={css(
        styles.star,
        last && styles.star__last,
        grownedUp && styles.grownedUpStar
      )}
    >
      <Icon />
    </div>
  );
};

export default Star;
