// dependencies
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

// components
import UIContainer from "@gdf/resources/src/components/UIContainer";
import { Row, Col } from "@gdf/resources/src/components/Grid2";
import Stack from "@gdf/resources/src/components/Stack";
import RoomCard from "@gdf/resources/src/components/RoomCard";
import Text from "@gdf/resources/src/components/Text";

// contexts
import { useConfiguration } from "@gdf/resources/src/contexts/configuration";
import { useRouters } from "@gdf/resources/src/contexts/routers";

// hocs
import useViewportMedia from "@gdf/resources/src/hocs/useViewportMedia";

// hooks
import { useFetcher } from "@gdf/resources/src/hooks/fetcher";

// helpers
import {
  roomCompressForLastSeen,
  roomSetLastSeenRoomList,
} from "@gdf/resources/src/helpers/room";

// constants
import { apiRouter } from "@gdf/shared/src/constants/router";

// libraries
import { generateUri, hasOwnProperty } from "@gdf/shared/src/libraries";
import {
  fromDate,
  fromString,
} from "@gdf/resources/src/libraries/EnhancedDate";
import { emToPx } from "@gdf/resources/src/libraries";

// normalizers
import { roomParseSearch } from "@gdf/resources/src/normalizers/room";

function LastSeenRoom(props) {
  const { preventRoomReference, roomToAdd } = props;

  const { matches } = useViewportMedia({ queries: [{ minWidth: 1100 }] });

  const intl = useIntl();

  const configuration = useConfiguration();

  const { frontRouter } = useRouters();

  const [roomList, setRoomList] = useState([]);

  const fetcher = useFetcher();

  useEffect(() => {
    const cachedFetchedRooms = {};

    const check = async ({ retries = 0 } = {}) => {
      // Récupère les hébergements récents stockés dans le local-storage.
      const rawRoomList = localStorage.getItem("lastSeenRoomList");

      // Stocke dans un objet normalisé les hébergements récents.
      const storageLastSeenRoomList = rawRoomList
        ? JSON.parse(rawRoomList)
        : [];

      // Supprime l'hébergement du détail courant si besoin.
      const roomList = [...storageLastSeenRoomList];

      if (
        roomToAdd &&
        roomList.every((room) => room.reference !== roomToAdd.reference)
      ) {
        roomList.push(roomCompressForLastSeen({ room: roomToAdd }));
      }

      // Vérifie pour les hébergements dépassés pour relancer un appel API.
      const outdatedRoomIndexList = roomList.reduce(
        (outdatedRoomIndexList, room, roomIndex) => {
          if (fromString(room.setAt).isLessOrEqualTo(fromDate().addDate(-1))) {
            outdatedRoomIndexList.push(roomIndex);
          }

          return outdatedRoomIndexList;
        },
        []
      );

      if (outdatedRoomIndexList.length > 0) {
        const outdatedRoomReferenceList = outdatedRoomIndexList
          .map(
            (outdatedRoomIndex) =>
              storageLastSeenRoomList[outdatedRoomIndex].reference
          )
          .filter(
            (roomReference) =>
              !hasOwnProperty(cachedFetchedRooms, roomReference)
          );

        if (outdatedRoomReferenceList.length > 0) {
          await fetcher(
            generateUri({
              router: apiRouter,
              name: "Api.Rooms",
              query: {
                refs: outdatedRoomReferenceList,
              },
            })
          ).then((response) => {
            const data = roomParseSearch({
              data: response?.body,
              configuration,
            });

            for (const room of data?.data ?? []) {
              cachedFetchedRooms[room.reference] = room;
            }
          });
        }

        outdatedRoomIndexList.forEach((outdatedRoomIndex) => {
          const outdatedRoomReference =
            storageLastSeenRoomList[outdatedRoomIndex].reference;

          const room = cachedFetchedRooms[outdatedRoomReference];

          if (room) {
            // Si l'hébergement est présent dans les data de mise à jour.

            roomList.splice(
              outdatedRoomIndex,
              1,
              roomCompressForLastSeen({ room })
            );
          } else {
            roomList.splice(outdatedRoomReference, 1);
          }
        });
      }

      if (
        rawRoomList !== localStorage.getItem("lastSeenRoomList") &&
        retries < 5
      ) {
        // Si la valeur du local-storage a été modifié entre temps.

        check({ retries: retries + 1 });
      } else {
        const truncatedRoomList = roomList.slice(-7);

        roomSetLastSeenRoomList({ roomList: truncatedRoomList });
        setRoomList(
          truncatedRoomList.filter(
            (lastSeenRoom) => lastSeenRoom.reference !== preventRoomReference
          )
        );
      }
    };

    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handlePress(/*{ roomReference, context }*/) {
    // TODO: GTM : ACTION
  }

  if (roomList.length === 0) {
    return null;
  }

  return (
    <UIContainer isFullWidth={true}>
      <Stack marginTop={1} />

      <Text style={{ fontSize: emToPx(1.625), fontWeight: "500" }}>
        {intl.formatMessage({
          id: "front.containers.index.lastSeen.label",
        })}
      </Text>

      <Stack marginTop={1} />

      {matches ? (
        <Row type="cell" gutter={0.5} maxColumns={5}>
          {roomList.slice(-5).map((room) => {
            return (
              <Col key={room.reference}>
                <RoomCard
                  context="lastSeen"
                  flux="block-compact"
                  pricing={undefined}
                  room={room}
                  to={generateUri({
                    router: frontRouter,
                    name: "App.Action.RoomWithSlug",
                    parameters: {
                      lang: intl.locale,
                      slug: room.slug,
                    },
                  })}
                  onPress={handlePress}
                />
              </Col>
            );
          })}
        </Row>
      ) : (
        <div style={{ overflowX: "auto", overflowY: "hidden" }}>
          <Row type="inline" gutter={0.5}>
            {roomList.map((room) => {
              return (
                <Col
                  key={room.reference}
                  style={{ width: !matches && "18.75rem" }}
                >
                  <RoomCard
                    flux="block-compact"
                    pricing={undefined}
                    room={room}
                    to={generateUri({
                      router: frontRouter,
                      name: "App.Action.RoomWithSlug",
                      parameters: {
                        lang: intl.locale,
                        slug: room.slug,
                      },
                    })}
                  />
                </Col>
              );
            })}
          </Row>
        </div>
      )}

      <Stack marginTop={1} />
    </UIContainer>
  );
}

LastSeenRoom.propTypes = {
  preventRoomReference: PropTypes.string,
  roomToAdd: PropTypes.object,
};

export default LastSeenRoom;
