// dependencies
import React from "react";
import { useIntl } from "react-intl";
import { StyleSheet, css } from "aphrodite";

// components
import BaseComponent from "@gdf/resources/src/components/BaseComponent2";
import Heading from "@gdf/resources/src/components/Heading2";
import Link from "@gdf/resources/src/components/Link2";
import Text from "@gdf/resources/src/components/Text2";
import Tooltip from "@gdf/resources/src/components/Tooltip";

// constants
import { ROOM_TYPES } from "@gdf/resources/src/constants/room";

// libraries
import { capitalize } from "@gdf/shared/src/libraries";
import { emToPx } from "../../libraries";
import uppercaseFirst from "cloudspire-shared/utils/string/uppercaseFirst";

type RoomType = ReturnType<
  typeof import("@gdf/resources/src/normalizers/room").roomParseData
>;

type PropsType = {
  flux: "inline" | "block" | "block-compact";
  reference: string;
  type: RoomType["type"];
  name: RoomType["name"];
  zipcode: RoomType["zipcode"];
  city: RoomType["city"];
  distance: RoomType["distance"];
  to: string;
  registrationNumber: string;
  linkProps?: any;
  context?: "home" | "search" | "campaign" | "lastSeen";
  onPress?;
};

const getInfo = ({ city, zipcode, type, distance, intl }) => {
  let result = undefined;

  const typeValue = ROOM_TYPES[type];
  if (typeValue) {
    result = intl.formatMessage(
      { id: "app.constants.room.type.title" },
      { type }
    );
  }

  // city
  if (city) {
    const cityLocal = uppercaseFirst(city);
    result = result
      ? `${result} ${intl.formatMessage({
          id: "app.constants.at",
        })} ${cityLocal}`
      : `${capitalize(
          intl.formatMessage({ id: "app.constants.at" })
        )} ${cityLocal}`;
  }

  if (zipcode) {
    result = result ? `${result} - ${zipcode}` : zipcode;
  }
  if (distance > 0) {
    result = result ? `${result} (${distance} km)` : undefined;
  }

  return result;
};

const styles = StyleSheet.create({
  header: {
    flexDirection: "column",
  },
  nameWrapper: {
    display: "flex",
    maxWidth: "100%",
    overflow: "hidden",
    alignItems: "center",
  },
  name: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  name__link: {
    ":hover": {
      textDecoration: "underline",
    },
  },
  nameHeading: {
    fontSize: "1.25rem",
  },
  infoHeading: {
    fontSize: "0.875rem",
  },
  registrationNumber: {
    display: "inline-flex",
    marginLeft: emToPx(0.5),
  },
});

const RoomHeader: React.FunctionComponent<PropsType> = (props) => {
  const {
    type,
    name,
    to,
    city,
    zipcode,
    distance,
    registrationNumber,
    flux,
    reference,
    context,
    linkProps,
    onPress,
  } = props;

  const intl = useIntl();

  const info =
    "inline" !== flux ? getInfo({ city, zipcode, type, distance, intl }) : null;

  const handleClick = () => {
    onPress({ roomReference: reference, context });
  };

  return (
    <BaseComponent aStyle={styles.header}>
      <Heading level={3} aStyle={styles.nameHeading}>
        <div className={css(styles.nameWrapper)}>
          {to ? (
            <Link
              {...linkProps}
              aStyle={[styles.name, styles.name__link]}
              target="_blank"
              to={to}
              onClick={handleClick}
            >
              {name}
            </Link>
          ) : (
            <Text aStyle={styles.name}>{name}</Text>
          )}

          {registrationNumber && (
            <div className={css(styles.registrationNumber)}>
              <Tooltip
                message={intl.formatMessage(
                  { id: "app.components.room.registration.number" },
                  { number: registrationNumber }
                )}
              />
            </div>
          )}
        </div>
      </Heading>

      {name && (
        <Heading level={4} aStyle={styles.infoHeading}>
          {info}
        </Heading>
      )}
    </BaseComponent>
  );
};

export default RoomHeader;
