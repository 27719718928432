// libraries
import { get } from "@gdf/shared/src/libraries";

/**
 * Récupère la liste des trophées
 * @param {object} param0
 * @param {object} param0.room
 * @param {object} [param0.options]
 * @param {object} [param0.options.inheritFromChildren]
 */
export default function roomGetTrophyList(
  { room },
  options: { inheritFromChildren?: boolean } = {}
) {
  const { inheritFromChildren = true } = options;

  let trophyList = [...get(room, "trophyList", [])];

  if (inheritFromChildren) {
    for (const childRoom of get(room, "children", [])) {
      trophyList.push(...get(childRoom, "trophyList", []));
    }

    // Supprime les doublons
    trophyList = trophyList.filter(function (trophy1, index, trophyList) {
      return (
        index === trophyList.findIndex((trophy2) => trophy2.id === trophy1.id)
      );
    });
  }

  return trophyList;
}
