// dependencies
import React from "react";
import { useIntl } from "react-intl";

// components
import BaseComponent from "@gdf/resources/src/components/BaseComponent2";
import { Row, Col } from "@gdf/resources/src/components/Grid2";
import Text from "@gdf/resources/src/components/Text2";
import {
  IconCapacityO,
  IconChamberO,
  IconBedO,
  IconAnimalsO,
  IconAnimalnoO,
  IconRestaurantO,
  IconTourismeEtHandicap,
  IconSurfaceO,
} from "@gdf/svg-icon-library";

// constants
import { ROOM_CATEGORY_VALUE_CAMPING } from "@gdf/resources/src/constants/room";

type PropsType = {
  flux: "block" | "inline" | "detail";
  categoryList: Array<
    keyof typeof import("@gdf/resources/src/constants/room").ROOM_CATEGORIES
  >;
  capacity: number;
  beds: number;
  chambers: number;
  arePetsAllowed: boolean;
  surface: number;
  hostTable: number;
  handicapList: Array<string>;
  aStyle?;
};

const RoomMetaList: React.FunctionComponent<PropsType> = (props) => {
  const {
    categoryList,
    flux,
    beds,
    capacity,
    chambers,
    arePetsAllowed,
    surface,
    hostTable,
    handicapList,
    aStyle,
  } = props;

  const intl = useIntl();

  return (
    <BaseComponent aStyle={aStyle}>
      <Row
        type="inline"
        flexWrap={true}
        gutter={{
          vertical: 0.125,
          horizontal: 0.5,
        }}
      >
        {capacity > 0 && (
          <Col>
            <Text>
              <IconCapacityO />
              &nbsp;
              {intl.formatMessage(
                { id: "app.component.room.meta.capacity" },
                { capacity }
              )}
            </Text>
          </Col>
        )}

        {["detail", "inline"].includes(flux) && surface > 0 && (
          <Col>
            <Text>
              <IconSurfaceO />
              &nbsp;
              {intl.formatMessage(
                { id: "app.component.room.meta.surface" },
                { surface }
              )}
            </Text>
          </Col>
        )}

        {["block"].includes(flux) && chambers > 0 && (
          <Col>
            <Text>
              <IconChamberO />
              &nbsp;
              {intl.formatMessage(
                { id: "app.component.room.meta.chambers" },
                {
                  chambers,
                  isCamping: categoryList.includes(ROOM_CATEGORY_VALUE_CAMPING)
                    ? "yes"
                    : "no",
                }
              )}
            </Text>
          </Col>
        )}

        {beds > 0 && (
          <Col>
            <Text>
              <IconBedO />
              &nbsp;
              {intl.formatMessage(
                { id: "app.component.room.meta.beds" },
                { beds }
              )}
            </Text>
          </Col>
        )}

        {arePetsAllowed ? (
          <Col>
            <Text>
              <IconAnimalsO />
              &nbsp;
              {intl.formatMessage({
                id: "app.component.room.meta.allowedAnimals",
              })}
            </Text>
          </Col>
        ) : (
          <Col>
            <Text>
              <IconAnimalnoO />
              &nbsp;
              {intl.formatMessage({
                id: "app.component.room.meta.forbiddenAnimals",
              })}
            </Text>
          </Col>
        )}

        {["inline", "block"].includes(flux) && hostTable > 0 && (
          <Col>
            <Text>
              <IconRestaurantO />
              &nbsp;
              {intl.formatMessage({
                id: "app.component.room.meta.hostTable",
              })}
            </Text>
          </Col>
        )}

        {["inline", "block"].includes(flux) && handicapList.length > 0 && (
          <Col>
            <Text>
              <IconTourismeEtHandicap />
              &nbsp;
              {intl.formatMessage({
                id: "app.component.room.meta.handicap",
              })}
            </Text>
          </Col>
        )}
      </Row>
    </BaseComponent>
  );
};

export default RoomMetaList;
