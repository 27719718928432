// dependencies
import React from "react";
import { StyleSheet } from "aphrodite";

// components
import Text from "@gdf/resources/src/components/Text2";

const styles = StyleSheet.create({
  heading: {
    fontWeight: "normal",
    margin: 0,
    padding: 0,
    fontSize: "1rem",
  },
});

type PropsType = {
  level: 1 | 2 | 3 | 4 | 5 | 6;
  style?: React.CSSProperties;
  aStyle?;
} & React.ComponentProps<typeof Text>;

const Heading: React.FunctionComponent<PropsType> = (props) => {
  const { level, aStyle, ...otherProps } = props;

  return (
    <Text aStyle={[styles.heading, aStyle]} as={`h${level}`} {...otherProps} />
  );
};

export default Heading;
