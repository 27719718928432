// dependencies
import React from "react";
import { StyleSheet, css } from "aphrodite";
import { useIntl } from "react-intl";
import {
  IconGdfTrophy2017,
  IconGdfTrophy2018,
  IconGdfTrophy2019,
  IconGdfTrophy2020,
  IconGdfTrophy2021,
  IconGdfTrophy2022,
  IconGdfTrophy2023,
  IconGdfTrophy2024,
} from "@gdf/svg-icon-library";

// components
import BaseComponent from "@gdf/resources/src/components/BaseComponent2";

// constants
import {
  ROOM_TROPHY_VALUE_GDF_2017,
  ROOM_TROPHY_VALUE_GDF_2018,
  ROOM_TROPHY_VALUE_GDF_2019,
  ROOM_TROPHY_VALUE_GDF_2020,
  ROOM_TROPHY_VALUE_GDF_2021,
  ROOM_TROPHY_VALUE_GDF_2022,
  ROOM_TROPHY_VALUE_GDF_2023,
  ROOM_TROPHY_VALUE_GDF_2024,
} from "@gdf/resources/src/constants/room";

type PropsType = {
  trophy: ReturnType<
    typeof import("@gdf/resources/src/normalizers/room").roomParseData
  >["trophyList"][number];
};

const styles = StyleSheet.create({
  trophy: {
    width: "2rem",
    height: "2rem",
    borderRadius: "0.1875rem",
    backgroundColor: "#ffffff",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    fontSize: "1.5rem",
    lineHeight: 0,
  },
});

const getComponent = ({ trophyId }) => {
  switch (trophyId) {
    case ROOM_TROPHY_VALUE_GDF_2017:
      return IconGdfTrophy2017;

    case ROOM_TROPHY_VALUE_GDF_2018:
      return IconGdfTrophy2018;

    case ROOM_TROPHY_VALUE_GDF_2019:
      return IconGdfTrophy2019;

    case ROOM_TROPHY_VALUE_GDF_2020:
      return IconGdfTrophy2020;

    case ROOM_TROPHY_VALUE_GDF_2021:
      return IconGdfTrophy2021;

    case ROOM_TROPHY_VALUE_GDF_2022:
      return IconGdfTrophy2022;

    case ROOM_TROPHY_VALUE_GDF_2023:
      return IconGdfTrophy2023;

    case ROOM_TROPHY_VALUE_GDF_2024:
      return IconGdfTrophy2024;

    default:
      return () => null;
  }
};

const RoomTrophy: React.FunctionComponent<PropsType> = (props) => {
  const { trophy } = props;

  const intl = useIntl();

  const { id } = trophy;

  const Icon = getComponent({ trophyId: id });

  const label = intl.formatMessage(
    {
      id: "app.components.roomTrophy.label",
    },
    {
      trophy: id,
    }
  );

  return (
    <BaseComponent aStyle={styles.trophy} aria-label={label} title={label}>
      <div className={css(styles.icon)}>
        <Icon />
      </div>
    </BaseComponent>
  );
};

export default RoomTrophy;
